(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/app/router/assets/javascripts/router.js') >= 0) return;  svs.modules.push('/components/app/router/assets/javascripts/router.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const {
  BrowserRouter,
  Link,
  NavLink,
  Prompt,
  Redirect,
  Route,
  Switch,
  generatePath,
  matchPath,
  withRouter
} = ReactRouterDOM;
const {
  HandlePathContainer
} = svs.app.ReactRouter;
const {
  roles
} = svs.core.userSession;
const {
  routesMetaData
} = svs.core.data;
const {
  ErrorBoundary,
  GenericError
} = svs.components.errorBoundary;

const generateRoleRouter = (role, authRedirect) => {
  const RoleRouter = _ref => {
    let {
      basename,
      children,
      render
    } = _ref;
    return React.createElement(ErrorBoundary, {
      renderOnError: GenericError
    }, React.createElement(BrowserRouter, {
      basename: basename
    }, React.createElement(HandlePathContainer, {
      authRedirect: authRedirect,
      render: render,
      role: role,
      routesMetaData: routesMetaData
    }, children)));
  };
  RoleRouter.displayName = "".concat(role, "Router");
  return RoleRouter;
};

Route.propTypes = _objectSpread(_objectSpread({}, Route.propTypes), {}, {
  path: (props, propName) => {
    const path = props[propName];
    if (path && !Array.isArray(path) && !routesMetaData[path]) {
      throw new Error("An exact matching path must be defined in routes metadata, ".concat(path, " could not be found"));
    } else if (path && Array.isArray(path)) {
      path.forEach(currentPath => {
        if (currentPath && !routesMetaData[currentPath]) {
          throw new Error("An exact matching path must be defined in routes metadata, ".concat(currentPath, " could not be found in array"));
        }
      });
    }
  }
});
svs.app.ReactRouter = _objectSpread(_objectSpread({}, svs.app.ReactRouter), {}, {
  PlayerRouter: generateRoleRouter(roles.PLAYER, svs.components.customer_login.login),
  RetailerRouter: generateRoleRouter(roles.RETAILER, window.location.reload),
  InternalRouter: generateRoleRouter(roles.INTERNAL, window.location.reload),
  Link,
  NavLink,
  Prompt,
  Redirect,
  Route,
  Switch,
  generatePath,
  generateRoleRouter,
  matchPath,
  withRouter
});

 })(window);