(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/app/router/assets/javascripts/handle-path.js') >= 0) return;  svs.modules.push('/components/app/router/assets/javascripts/handle-path.js');
"use strict";

const _excluded = ["children", "render"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


(svs => {
  const {
    Component
  } = React;
  const {
    matchPath,
    withRouter
  } = ReactRouterDOM;
  const logger = svs.core.log.getLogger('app:router:handle-path');
  class HandlePath extends Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "state", {
        routeMatch: null
      });
    }
    static getDerivedStateFromProps(props) {
      const {
        location,
        routesMetaData
      } = props;

      const path = Object.keys(routesMetaData).filter(path => matchPath(location.pathname, {
        path,
        exact: true
      }))[0];
      return {
        routeMatch: path ? routesMetaData[path] : null
      };
    }
    shouldComponentUpdate(nextProps, nextState) {
      const {
        location,
        role,
        authRedirect
      } = nextProps;
      const {
        routeMatch
      } = nextState;
      if (!routeMatch) {
        logger.info("".concat(location.pathname, " does not match any defined route, components are not updated."));
        return false;
      }
      svs.core.data.roles = (routeMatch.roles || []).slice();

      const hasRolePermission = routeMatch.roles && routeMatch.roles.length > 0 ? routeMatch.roles.indexOf(role) !== -1 : true;
      if (!hasRolePermission) {
        logger.info("User does not have the required permission, redirecting.");
        authRedirect();
        return false;
      }
      return true;
    }
    componentDidUpdate(prevProps, prevState) {
      const {
        routeMatch: prevRouteMatch
      } = prevState;
      const {
        routeMatch
      } = this.state;
      if (routeMatch !== prevRouteMatch) {
        if (routeMatch.title) {
          if (svs.core.data && svs.core.data.routesMetaData && svs.core.data.routesMetaData['']) {
            svs.core.data.title = svs.core.data.title || {};
            svs.core.data.title.moduleTitle = svs.core.data.routesMetaData[''].title;
          }
          document.title = this.replacePlaceholders(routeMatch.title, svs.core.data.title);
        }
        if (routeMatch.metaDescription) {
          document.querySelector('meta[name=description]').content = this.replacePlaceholders(routeMatch.metaDescription, svs.core.data.metaDescription);
        }
        if (routeMatch.metaKeywords) {
          document.querySelector('meta[name=keywords]').content = this.replacePlaceholders(routeMatch.metaKeywords, svs.core.data.metaKeywords);
        }
      }
    }
    replacePlaceholders() {
      let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const placeholders = text.match(/({{([\w-]*)}})+/g) || [];
      placeholders.forEach(placeholder => {
        text = text.replace(new RegExp(placeholder, 'g'), data[placeholder.replace(/{|}/g, '')] || '');
      });
      return text;
    }
    render() {
      const _this$props = this.props,
        {
          children,
          render
        } = _this$props,
        routeProps = _objectWithoutProperties(_this$props, _excluded);
      if (children && render) {
        throw new Error('Use children or render, not both');
      }
      return render ? render(routeProps) : children;
    }
  }
  svs.app.ReactRouter.HandlePathComponent = HandlePath;
  svs.app.ReactRouter.HandlePathContainer = withRouter(HandlePath);
})(svs);

 })(window);